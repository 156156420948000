import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Stack from "@mui/system/Stack";
import TextField from "@mui/material/TextField";

import React, { useState } from "react";
import { Container, Button, Typography } from "@mui/material";

export default function MediaCard(props) {
  const Item = styled("div")(({ theme }) => ({
    backgroundColor: "#fff",
    padding: theme.spacing(1),

    alignContent: "center",

    ...theme.applyStyles("dark", {
      backgroundColor: "#262B32",
    }),
  }));
  return (
    <Stack direction="row" spacing={2} width={"650px"}>
      <Item>
        <Box
          sx={{
            boxShadow: 3,
            bgcolor: "#fff",
            color: "grey.800",
            p: 1,
            m: 1,
            borderRadius: 2,
            textAlign: "center",
            fontSize: "0.875rem",
            fontWeight: "700",
          }}>
          {" "}
          <img style={{ height: "100px" }} src="EZlynx.png"></img>
        </Box>
      </Item>
      <Item>
        <Box
          sx={{
            boxShadow: 3,
            bgcolor: "#fff",
            color: "grey.800",
            p: 1,
            m: 1,
            borderRadius: 2,
            textAlign: "center",
            fontSize: "0.875rem",
            fontWeight: "700",
          }}>
          {" "}
          <img style={{ height: "100px" }} src="ITC.png"></img>
        </Box>
      </Item>
      <Item>
        <Box
          sx={{
            boxShadow: 3,
            bgcolor: "#fff",
            color: "grey.800",
            p: 1,
            m: 1,
            borderRadius: 2,
            textAlign: "center",
            fontSize: "0.875rem",
            fontWeight: "700",
          }}>
          {" "}
          <img style={{ height: "100px" }} src="Z.png"></img>
        </Box>
      </Item>

      <Item sx={{ alignContent: "center" }}>
        <h3>...</h3>
      </Item>
    </Stack>
  );
}
