// src/components/Header.js
import { AppBar, Toolbar, Typography } from "@mui/material";
import Menu from "../Components/Menu";

const Header = () => (
  <AppBar position="static" color="white">
    <Menu />
  </AppBar>
);

export default Header;
