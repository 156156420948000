import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

function ScrollableModal({
  setpopup,
  openbox,
  message,
  premium,
  medicalPayment,
  uMID,
  showQuote,
}) {
  const [open, setOpen] = useState(openbox);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(
    (props) => {
      setOpen(openbox);
    },
    [openbox, setOpen]
  );

  const handleClose = () => {
    setOpen(false);
    setpopup();
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        open={open && !showQuote}
        onClose={handleClose}
        scroll="paper" // You can also use "body" for viewport-based scrolling
        aria-labelledby="scrollable-dialog-title">
        <DialogTitle id="scrollable-dialog-title">Payload</DialogTitle>
        <DialogContent dividers={true}>
          <p>{JSON.stringify(message)}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ScrollableModal;
