// src/JsonRpcClient.js
import axios from "axios";

const RpcClient = async (
  endpoint,
  method,
  params,
  username = process.env.REACT_APP_USER,
  password = process.env.REACT_APP_PASSWORD
) => {
  const payload = {
    jsonrpc: "2.0",
    method: method,
    params: params,
  };
  const basicAuth = "Basic " + btoa(username + ":" + password);

  try {
    const response = await axios.post(endpoint, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: basicAuth,
      },
    });
    if (response.data.error) {
      console.error("JSON-RPC Error:", response.data.error);
      return null;
    }
    return response.data.result;
  } catch (error) {
    console.error("Request Error:", error);
    return null;
  }
};

export default RpcClient;
