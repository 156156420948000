// src/components/Footer.js
import { Box, Typography } from '@mui/material';

import React from 'react'

function Footer() {
    return (
        <Box component="footer" sx={{ textAlign: 'center', py: 3, mt: 'auto', backgroundColor: '#f5f5f5' }}>
    <Typography variant="body1">© 19XX-2024 Grange Insurance. All Rights Reserved.</Typography>
  </Box>
    )
}

export default Footer

