import React, { useEffect, useState, useCallback } from "react";
import { map, reduce, somethingElse, clone } from "underscore";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Select,
} from "@mui/material";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import InputMask from "react-input-mask";
import FormControl from "@mui/material/FormControl";

const CategoryForm = (props) => {
  const availableMedicalLimits = [
    {
      name: "$7,500",
      maxValue: 5,
      optionValue: 1,
      code: "opt_309",
    },
    {
      name: "$5,000",
      maxValue: 5,
      optionValue: 1,
      code: "opt_309",
    },
    {
      name: "$10,000",
      maxValue: 5,
      optionValue: 2,
      code: "opt_310",
    },
    {
      name: "$15,000",
      maxValue: 5,
      optionValue: 3,
      code: "opt_311",
    },
    {
      name: "$20,000",
      maxValue: 5,
      optionValue: 4,
      code: "opt_312",
    },
    {
      name: "$25,000",
      maxValue: 5,
      optionValue: 5,
      code: "opt_313",
    },
  ];

  const uIMDOptions = [
    {
      name: "$7,500",
      maxValue: 5,
      optionValue: 2,
      code: "z78isngduqsou6dhe4b68k7hpg8",
    },
    {
      name: "$25,000",
      maxValue: 5,
      optionValue: 2,
      code: "z78isngduqsou6dhe4b68k7hpg8",
    },
    {
      name: "$50,000",
      maxValue: 5,
      optionValue: 3,
      code: "zn2juqgsb6ljo275tl4j69m0dka",
    },
    {
      name: "$100,000",
      maxValue: 5,
      optionValue: 5,
      code: "zsahc20k8vbo47iftogik7as63a",
    },
  ];
  const [informationData, setInformationData] = useState(props.message);
  const [isFormValid, setIsFormValid] = useState(props.valid);
  const [medicalPaymentSelectedLimit, setMedicalPaymentSelectedLimit] =
    useState();
  const [uIMDSelectedLimit, setUIMDSelectedLimit] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const validate = (path) => {};

  const handleSubmit = (event, val = "") => {
    event.preventDefault();
    console.log("Form submitted");
  };

  function setJsonValueByPath(obj, path, value) {
    const keys = path.split(".");
    const lastKey = keys.pop();
    const lastObj = keys.reduce((acc, key) => {
      if (!acc[key]) acc[key] = {};
      if (key === "drivers") {
        return acc[key][0];
      } else {
        return acc[key];
      }
    }, obj);
    lastObj[lastKey] = value;
  }

  

  const isInputValid1 = (jsonObject) => {
    // Check if all values are empty
    for (const key in jsonObject) {
      const value = jsonObject[key];

      // Check if the value is a string and empty
      if (typeof value === "string" && value.trim() === "" && key != "sSN") {
        // setIsFormValid(false);
        return false; // Move to the next key-value pair
      }

      // Check if the value is an object and recursively check if it's empty
      if (typeof value === "object" && isInputValid1(value)) {
        continue; // Move to the next key-value pair
      }
    }
    return true;
  };

  const myNestedJson = {
    name: "John Doe",
    age: 30,
    hobbies: ["reading", "gaming"],
    address: {
      street: "123 Main St",
      city: "",
      zipCode: "12345",
    },
    family: {
      parents: ["Alice", "Bob"],
      siblings: [{ name: "Eve" }],
    },
  };
  const handleInputChange = useCallback(
    (value, path) => {
      const updatedJsonData = { ...informationData };
      setJsonValueByPath(updatedJsonData, path, value.trim());
      setInformationData(updatedJsonData);
     
    },
    [informationData, setInformationData]
  );

  const onDateChange = useCallback(
    (value) => {
      const updatedJsonData = { ...informationData };
      const seperatedDate = value.split("/");
      if (seperatedDate.length == 3 && !isNaN(seperatedDate[2])) {
        updatedJsonData.account.periodStartDate.month = seperatedDate[0];
        updatedJsonData.account.periodStartDate.day = seperatedDate[1];
        updatedJsonData.account.periodStartDate.year = seperatedDate[2];
        setInformationData(updatedJsonData);
      }
      setSelectedDate(value);
    },
    [informationData, setInformationData]
  );

  return (
    <>
      <Container maxWidth="sm">
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, boxShadow: 3 }}>
          <Box component="fieldset">
            <legend style={{ fontWeight: "bold" }}>Account Detail</legend>
            <TextField
              variant="outlined"
              sx={{ paddingRight: "5px", borderColor: "red" }}
              margin="normal"
              required
              fullWidth
              id="fName"
              label="First Name"
              name="fName"
              autoFocus
              error={
                props.clicked
                  ? !informationData.account.firstName.trim()
                  : !informationData.account.firstName
              }
              // helperText={
              //   !informationData.account.firstName
              //     ? "This field is required."
              //     : ""
              // }
              value={informationData.account.firstName}
              onChange={(event) =>
                handleInputChange(event.target.value, "account.firstName")
              }
            />

            <TextField
              sx={{ paddingRight: "5px" }}
              margin="normal"
              required
              fullWidth
              id="lName"
              label="Last Name"
              name="lName"
              value={informationData.account.lastName}
              error={
                props.clicked
                  ? !informationData.account.lastName.trim()
                  : !informationData.account.lastName
              }
              helperText={
                !informationData.account.lastName
                  ? "This field is required."
                  : ""
              }
              onChange={(event) =>
                handleInputChange(event.target.value, "account.lastName")
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="address1"
              label="Address Line1"
              name="address1"
              value={informationData.account.addressLine1}
              error={
                props.clicked
                  ? !informationData.account.addressLine1.trim()
                  : !informationData.account.addressLine1
              }
              // helperText={
              //   !informationData.account.addressLine1
              //     ? "This field is required."
              //     : ""
              // }
              onChange={(event) =>
                handleInputChange(event.target.value, "account.addressLine1")
              }
            />
            <Box sx={{ display: "flex" }}>
              <TextField
                sx={{ paddingRight: "5px" }}
                margin="normal"
                required
                fullWidth
                id="city"
                label="City"
                name="city"
                value={informationData.account.city}
                error={
                  props.clicked
                    ? !informationData.account.city.trim()
                    : !informationData.account.city
                }
                // helperText={
                //   !informationData.account.city ? "This field is required." : ""
                // }
                onChange={(event) =>
                  handleInputChange(event.target.value, "account.city")
                }
              />

              <FormControl
                fullWidth
                sx={{ marginTop: "15px", paddingRight: "5px" }}>
                <InputLabel id="demo-simple-select-label">State*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  required
                  id="demo-simple-select"
                  value={informationData.account.state}
                  label="State"
                  //error={!informationData.account.state}
                  error={
                    props.clicked
                      ? !informationData.account.state.trim()
                      : !informationData.account.state
                  }
                  // helperText={
                  //   !informationData.account.state
                  //     ? "This field is required."
                  //     : ""
                  // }
                  onChange={(event) =>
                    handleInputChange(event.target.value, "account.state")
                  }>
                  <MenuItem value="IN">IN</MenuItem>
                </Select>
              </FormControl>

              <TextField
                margin="normal"
                required
                fullWidth
                id="zip"
                label="Zip Code*"
                name="zip"
                value={informationData.account.postalCode}
                // error={!informationData.account.postalCode}
                error={
                  props.clicked
                    ? !informationData.account.postalCode.trim()
                    : !informationData.account.postalCode
                }
                // helperText={
                //   !informationData.account.postalCode
                //     ? "This field is required."
                //     : ""
                // }
                onChange={(event) =>
                  handleInputChange(event.target.value, "account.postalCode")
                }
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <InputMask
                mask="99/99/9999"
                style={{ fontSize: "500px" }}
                value={selectedDate}
                onChange={(event) => onDateChange(event.target.value)}
                disabled={false}
                maskChar="_"
                alwaysShowMask={false}>
                {() => (
                  <TextField
                    label="Period Start Date"
                    required
                    sx={{
                      width: "100%",
                      marginTop: "15px",
                      paddingRight: "5px",
                    }}
                    //error={!informationData.account.periodStartDate.month}
                    // error={
                    //   props.clicked
                    //     ? !isNaN(informationData.account.periodStartDate.day.trim())
                    //     : !isNaN(informationData.account.periodStartDate.day.trim())
                    // }
                    // helperText={
                    //   !informationData.account.periodStartDate.month
                    //     ? "This field is required."
                    //     : ""
                    // }
                  />
                )}
              </InputMask>
              <InputMask
                mask="999-99-9999"
                style={{ fontSize: "500px" }}
                // value={''}
                disabled={false}
                maskChar="_"
                alwaysShowMask={false}>
                {() => (
                  <TextField
                    id="ssn"
                    label="SSN"
                    name="ssn"
                    sx={{ width: "100%", marginTop: "15px" }}
                  />
                )}
              </InputMask>
            </Box>
          </Box>
          <Box component="fieldset">
            <legend style={{ fontWeight: "bold" }}>Driver Detail</legend>
            <TextField
              margin="normal"
              required
              fullWidth
              id="fName"
              label="First Name"
              name="fName"
              value={informationData.driver.firstName}
              onChange={(event) =>
                handleInputChange(event.target.value, "driver.firstName")
              }
              //  error={!informationData.driver.firstName}
              error={
                props.clicked
                  ? !informationData.driver.firstName.trim()
                  : !informationData.driver.firstName
              }
              // helperText={
              //   !informationData.driver.firstName
              //     ? "This field is required."
              //     : ""
              // }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lName"
              label="Last Name"
              name="lName"
              value={informationData.driver.lastName}
              onChange={(event) =>
                handleInputChange(event.target.value, "driver.lastName")
              }
              error={
                props.clicked
                  ? !informationData.driver.lastName.trim()
                  : !informationData.driver.lastName
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="liscence"
              label="license Number"
              name="liscence"
              value={informationData.driver.licenseNumber}
              onChange={(event) =>
                handleInputChange(event.target.value, "driver.licenseNumber")
              }
              error={
                props.clicked
                  ? !informationData.driver.licenseNumber.trim()
                  : !informationData.driver.licenseNumber
              }
            />
          </Box>
          <Box component="fieldset">
            <legend style={{ fontWeight: "bold" }}>Coverage</legend>

            <FormControl
              fullWidth
              sx={{ marginTop: "15px", paddingRight: "5px" }}>
              <InputLabel fullWidth id="demo-medical-select-label">
                Medical Payment*
              </InputLabel>
              <Select
                required
                error={
                  props.clicked
                    ? !informationData.coverage.medicalPayment.trim()
                    : !informationData.coverage.medicalPayment
                }
                sx={{ width: "100%", height: "50px", marginBottom: "10px" }}
                labelId="demo-medical-select-label"
                id="medicalPayment"
                label="medical Payment"
                value={informationData.coverage.medicalPayment}
                onChange={(event) =>
                  handleInputChange(
                    event.target.value,
                    "coverage.medicalPayment"
                  )
                }>
                {availableMedicalLimits.map((item, index) => (
                  <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              sx={{ marginTop: "15px", paddingRight: "5px" }}>
              <InputLabel fullWidth id="demo-simple-select-label">
                Underinsured Motorist - Property Damage*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                required
                id="demo-simple-select"
                value={informationData.coverage.uMID}
                label="Underinsured Motorist - Property Damage"
                onChange={(event) =>
                  handleInputChange(event.target.value, "coverage.uMID")
                }
                error={
                  props.clicked
                    ? !informationData.coverage.uMID.trim()
                    : !informationData.coverage.uMID
                }>
                {uIMDOptions.map((item, index) => (
                  <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ minWidth: 120 }}></Box>
      </Container>
    </>
  );
};

export default CategoryForm;
